.tbl {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.th {
    text-align: center;
}

.th:nth-child(1) {
    text-align: left;
}

.td {
    white-space: nowrap;
    text-align: center;
    min-width: 30px;
}

.td:nth-child(1) {
    text-align: left;
}
